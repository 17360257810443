import React from "react"
import SlideToggle from "react-slide-toggle"
import SEO from "../components/seo"
import Section from "../components/Section"
import faqs from '../data/faqs.json'

const FaqPageMobile = ({location}) => {
  return (
    <main id={`page--faqs-mobile`} >
      <SEO title="FAQ's" />
      <Section id="faq">
      <h1 className='fs-28 fs-lg-56 fs-xl-56 text-center font-bold ff-secondary text-gradient mt-1 mb-6' >
        FAQ's
        </h1> 
        <ul className="mt-4 md:mt-4">
            {faqs[0].map((faq, index) => {
              return (
                <SlideToggle key={`faq-${index}`} collapsed render={({ toggle, setCollapsibleElement, toggleState }) => {
                  return  <div className="my-collapsible text-third my-6 text-left ">
                  <button className="my-collapsible__toggle fs-20 fs-md-22 fs-lg-24 text-left " onClick={toggle}>
                    {faq.question}
                  </button>
                  <div className="my-collapsible__content my-3 text-left " ref={setCollapsibleElement}>
                    <div className="my-collapsible__content-inner text-fourth text-left  " dangerouslySetInnerHTML={{ __html: faq.answer }} />
                  </div>
                </div>
                }}/>
              )
            })}
          </ul>
          <h2 className='fs-16 fs-lg-28 fs-xl-28 font-bold ff-secondary text-gradient mt-3 mb-1' > 
          Creator Questions
          </h2>
          <ul>
            {faqs[1].map((faq, index) => {
              return (
                <SlideToggle key={`faq-${index}`} collapsed render={({ toggle, setCollapsibleElement, toggleState }) => {
                  return  <div className="my-collapsible text-third my-6 text-left ">
                  <button className="my-collapsible__toggle fs-20 fs-md-22 fs-lg-24 text-left " onClick={toggle}>
                    {faq.question}
                  </button>
                  <div className="my-collapsible__content my-3 text-left " ref={setCollapsibleElement}>
                    <div className="my-collapsible__content-inner text-fourth text-left  " dangerouslySetInnerHTML={{ __html: faq.answer }} />
                  </div>
                </div>
                }}/>
              )
            })}
          </ul> 
          <h2 className='fs-16 fs-lg-28 fs-xl-28 font-bold ff-secondary text-gradient mt-3 mb-1' > 
         Fan Questions
          </h2>
          <ul>
            {faqs[2].map((faq, index) => {
              return (
                <SlideToggle key={`faq-${index}`} collapsed render={({ toggle, setCollapsibleElement, toggleState }) => {
                  return  <div className="my-collapsible text-third my-6 text-left ">
                  <button className="my-collapsible__toggle fs-20 fs-md-22 fs-lg-24 text-left " onClick={toggle}>
                    {faq.question}
                  </button>
                  <div className="my-collapsible__content my-3 text-left " ref={setCollapsibleElement}>
                    <div className="my-collapsible__content-inner text-fourth text-left  " dangerouslySetInnerHTML={{ __html: faq.answer }} />
                  </div>
                </div>
                }}/>
              )
            })}
          </ul>
          <h2 className='fs-16 fs-lg-28 fs-xl-28 font-bold ff-secondary text-gradient mt-3 mb-1' > 
          General Question
          </h2>
          <ul>
            {faqs[3].map((faq, index) => {
              return (
                <SlideToggle key={`faq-${index}`} collapsed render={({ toggle, setCollapsibleElement, toggleState }) => {
                  return  <div className="my-collapsible text-third my-6 text-left ">
                  <button className="my-collapsible__toggle fs-20 fs-md-22 fs-lg-24 text-left " onClick={toggle}>
                    {faq.question}
                  </button>
                  <div className="my-collapsible__content my-3 text-left " ref={setCollapsibleElement}>
                    <div className="my-collapsible__content-inner text-fourth text-left  " dangerouslySetInnerHTML={{ __html: faq.answer }} />
                  </div>
                </div>
                }}/>
              )
            })}
          </ul>
      </Section>
    </main>
  )
}

export default FaqPageMobile
